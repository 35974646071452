import React, { useState, useEffect } from 'react';
import logo from '../img/Nova Namera logo.png';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className={`transition-all duration-300 ease-in-out ${isScrolled ? 'bg-zinc-900' : 'bg-transparent'} fixed w-full z-20 top-0 start-0 dark:border-gray-600`}>
            <nav className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                    <img src={logo} className="h-12" alt="Nova Namera Logo, the best at 3D and VFX" />
                </a>
                <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
                   <a href="#contact"><button
                        className="text-[#be14e9] bg-white hover:bg-gray-50 focus:ring-4 focus:outline-none focus:ring-[#be14e9]-300 font-medium rounded-lg text-sm px-4 lg:py-4 py-2.5 text-center">Contact
                        Us</button></a> 
                    <button onClick={toggleMenu} type="button" 
                        className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                        aria-controls="navbar-sticky" aria-expanded={isMenuOpen ? "true" : "false"}>
                        <span className="sr-only">Open main menu</span>
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 17 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                d="M1 1h15M1 7h15M1 13h15" />
                        </svg>
                    </button>
                </div>
                <div className={`items-center justify-between ${isMenuOpen ? 'block' : 'hidden'} w-full md:flex md:w-auto md:order-1`}
                    id="navbar-sticky">
                        <ul className={`flex flex-col p-4 md:p-0 mt-4 font-medium border border-zinc-100 rounded-lg ${isMenuOpen ? 'bg-zinc-900' : 'bg-zinc-50'} md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-transparent dark:bg-transparent md:dark:bg-transparent dark:border-zinc-700`}>
                        <li>
                            <a href="/"
                                className="block py-2 px-3 text-white rounded md:bg-transparent md:text-white md:p-0 md:dark:text-[#be14e9]"
                                aria-current="page">Home</a>
                        </li>
                        <li>
                            <a href="#services"
                                className="block py-2 px-3 text-white rounded hover:bg-zinc-100 
                                md:hover:bg-transparent md:hover:text-[#be14e9] md:p-0 md:dark:hover:text-[#be14e9] 
                                dark:text-white dark:hover:bg-zinc-700 dark:hover:text-white md:dark:hover:bg-transparent 
                                dark:border-zinc-700">Services</a>
                        </li>
                        <li>
                            <a href="#projects"
                                className="block py-2 px-3 text-white rounded hover:bg-zinc-100 
                                md:hover:bg-transparent md:hover:text-[#be14e9] md:p-0 md:dark:hover:text-[#be14e9] 
                                dark:text-white dark:hover:bg-zinc-700 dark:hover:text-white md:dark:hover:bg-transparent 
                                dark:border-zinc-700">Projects</a>
                        </li>
                        <li>
                            <a href="#newsletter"
                                className="block py-2 px-3 text-white rounded hover:bg-zinc-100 
                                md:hover:bg-transparent md:hover:text-[#be14e9] md:p-0 md:dark:hover:text-[#be14e9] 
                                dark:text-white dark:hover:bg-zinc-700 dark:hover:text-white md:dark:hover:bg-transparent 
                                dark:border-zinc-700">Newsletter</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
}

export default Header;
